export var menuItems = [
  {
    name: "products",
    label: "Products",
    items: [
      { name: "transaction-categorization-api", label: "Transaction Categorization API" },
      // { name: "pfm", label: "AI Personal Financial Manager API" },
      { name: "credit-scoring-api", label: "Credit Scoring API" },
      { name: "recommendation-api", label: "Recommendation API" },
      // { name: "carbon-engine", label: "Carbon Footprint Engine" },
    ],
  },
  {
    name: "#use-cases",
    label: "Use Cases",
  },
  // {
  // 	name: 'use-cases',
  // 	label: 'Showcase',
  // 	items: [
  // 		{ name: 'personal-finance-mgmt', label: 'Personal Finance Management' },
  // 		{ name: 'wealth-mgmt', label: 'Wealth Management' },
  // 		{ name: 'sme-lending', label: 'SME Lending' },
  // 		{ name: 'consumer-lending', label: 'Consumer Lending' }
  // 	]
  // },
  {
    name: "about",
    label: "About",
    items: [
      {
        name: "https://www.linkedin.com/company/genify/",
        label: "Company",
        isExternal: true,
      },

      {
        name: "https://angel.co/company/genify-ai/jobs",
        label: "Careers",
        isExternal: true,
      },
      {
        name: "blog",
        label: "Blog",
      },
      {
        name: "https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=genify+bank+transformer&btnG=",
        label: "Research",
        isExternal: true,
      },
    ],
  },

  {
    name: "txn-data-gui",
    label: "Playground",
  },
  {
    name: "https://docs.pfm.genify.ai/",
    label: "API Docs",
    isExternal: true,
  },
  // {
  //   name: "txn-data-gui",
  //   label: "Transaction Data GUI",
  // },
  {
    name: "contact-us",
    label: "Talk to us",
  },
  // {
  //   name: "test",
  //   label: "Test",
  // },
];
