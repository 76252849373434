import React, { useContext } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import { Link as ReactLink } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";
import medium from "../../assets/image/svg/medium.svg";
import linkedin from "../../assets/image/svg/linkedin.svg";
import angellist from "../../assets/image/svg/angellist.svg";
import github from "../../assets/image/svg/github.svg";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity-f gr-hover-text-green"
      : "gr-text-color-opacity-f gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-text-color-opacity gr-hover-text-blue";

  return (
    <>
      <div
      // className={`${
      //   gContext.footer.theme === "dark"
      //     ? "dark-mode-texts bg-blackish-blue"
      //     : ""
      // }`}
      >
        
        <Row
          style={{ backgroundColor: "#f5f7fa" }}
          className=" pt-10 pb-10 pt-lg-25 px-lg-25 pb-lg-21 px-md-15 px-sm-15 px-xs-15 px-0"
        >
          <Col
            style={{ backgroundColor: "#f5f7fa", width: "100%" }}
            className="px-10 logo-and-icons"
          >
            <Logo white={false} className="footer-logo" />

            <br />
            <ul
              className="social-icons list-unstyled"
              style={{ paddingTop: "0.85rem" }}
            >
              <li className="mr-2">
                <a
                  href="https://www.linkedin.com/company/genify/"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="linked in icon"
                    src={linkedin}
                    style={{ height: "18px", width: "25px" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://medium.com/genifyai"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="medium icon"
                    src={medium}
                    style={{ height: "18px", width: "25px" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://angel.co/company/genify-ai/jobs"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="angellist icon"
                    src={angellist}
                    style={{ width: "18px", color: "#fff" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://github.com/genifyai"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="github icon"
                    src={github}
                    style={{ width: "20px" }}
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col className="mr-md-25 footer-right-reserved">
            <p>
              Genify AI Technologies is registered in the UAE at ADGM, with
              license 000007634. Genify AI Technologies Ltd. 2023 © All rights
              reserved
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
