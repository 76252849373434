import React, { useContext } from "react";
import './Footer.css';
import { Container, Row, Col, Button } from "react-bootstrap";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import { Link as ReactLink } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";
import medium from "../../assets/image/svg/medium.svg";
import linkedin from "../../assets/image/svg/linkedin.svg";
import angellist from "../../assets/image/svg/angellist.svg";
import github from "../../assets/image/svg/github.svg";

const Footer = () => {
  const gContext = useContext(GlobalContext);

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color-opacity-f gr-hover-text-green"
      : "gr-text-color-opacity-f gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-text-color-opacity gr-hover-text-blue";

  return (
    <>
      <div
      // className={`${
      //   gContext.footer.theme === "dark"
      //     ? "dark-mode-texts bg-blackish-blue"
      //     : ""
      // }`}
      >
        <Row
          className="pl-lg-25 px-6 pt-15 pt-lg-25 pb-lg-21 px-sm-5"
          style={{ backgroundColor: "#4A63A5" }}
        >
          <Col xs="13" lg="5" md="5">
            <h2 style={{ color: "white", fontSize: '58px' }}>Give it a Try - It's Free</h2>
            <ul>
              <li style={{ color: "white", fontWeight: "700" }}>
                Explore sample enriched data & insights.
              </li>
              <li style={{ color: "white", fontWeight: "700" }}>
                Query the API in a sandbox environment.
              </li>
              <li style={{ color: "white", fontWeight: "700" }}>
                Explore the extended API documentation.
              </li>
              <li style={{ color: "white", fontWeight: "700" }}>
                Test with up to 1000 transactions for free.
              </li>
              <li style={{ color: "white", fontWeight: "700" }}>
                Access API keys.
              </li>
            </ul>
            <Link to="/contact-us" className={linkClassName}>
              <Button
                className="gr-hover-y mb-10 p-2"
                style={{ backgroundColor: "#ff565b", border: "0" }}
                onClick={() =>
                  window.dataLayer.push({
                    page_type: "footer",
                    event: "contact_us",
                  })
                }
              >
                Talk to us
              </Button>
            </Link>
            {/* <p className="gr-text-11">
                With lots of unique blocks, you can easily build a page without
                coding. Build your next landing page.
              </p> */}
          </Col>
          <Col lg="6" md="8" className="offset-lg-0.5 offset-xl-0">
            <Row>
              <Col xs="6" lg="4">
                <div className="single-footer mb-13 mb-lg-9">
                  <p
                    className="footer-title gr-text-8-f mb-7"
                    style={{ color: "#000" }}
                  >
                    Products
                  </p>
                  <ul className="footer-list list-unstyled gr-text-11-f">
                    <li className="py-2">
                      <Link to="/recommendation-api" className={linkClassName}>
                        Recommendation API{" "}
                      </Link>
                    </li>
                    <li className="py-2" style={{ display: "flex" }}>
                      <Link
                        to="/recommendation-api"
                        className={linkClassName}
                        style={{ minWidth: "30vw" }}
                      >
                        Transaction Categorization API
                      </Link>
                    </li>
                    <li className="py-2">
                      <Link to="/credit-scoring-api" className={linkClassName}>
                        Credit Scoring API{" "}
                      </Link>
                    </li>
                    
                    {/* <li className="py-2">
                        <Link to="/pfm" className={linkClassName}>
                          AI Personal Financial Manager API{" "}
                        </Link>
                      </li> */}
                    {/* <li className='py-2'>
												<Link to='/carbon-engine' className={linkClassName}>
													Carbon Footprint Engine
												</Link>
											</li> */}
                  </ul>
                </div>
              </Col>
              {/* <Col xs='6' lg='3'>
									<div className='single-footer mb-13 mb-lg-9'>
										<p className='footer-title gr-text-8-f mb-7' style={{ color: '#000' }}>
											Showcase
										</p>
										<ul className='footer-list list-unstyled gr-text-11'>
											<li className='py-2'>
												<Link to='/personal-finance-mgmt' className={linkClassName}>
													Personal Finance Management
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/wealth-mgmt' className={linkClassName}>
													Wealth Management
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/sme-lending' className={linkClassName}>
													SME Lending Lending
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/consumer-lending' className={linkClassName}>
													Consumer Lending
												</Link>
											</li>
										</ul>
									</div>
								</Col> */}
              <Col xs="6" lg="4">
                <div className="single-footer mb-13 mb-lg-9">
                  <p
                    className="footer-title gr-text-8-f mb-7"
                    style={{ color: "#000" }}
                  >
                    About
                  </p>
                  <ul className="footer-list list-unstyled gr-text-11">
                    <li className="py-1">
                      <a
                        href="https://www.linkedin.com/company/genify/"
                        className={linkClassName}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Company
                      </a>
                    </li>
                    <li className="py-1">
                      <a
                        href="https://angel.co/company/genify-ai/jobs"
                        target="_blank"
                        rel="noreferrer"
                        className={linkClassName}
                      >
                        Careers
                      </a>
                    </li>
                    <li className="py-1">
                      <Link to="/blog" className={linkClassName}>
                        Blog
                      </Link>
                    </li>
                    <li className="py-1">
                      <a
                        href="https://scholar.google.com/scholar?hl=en&as_sdt=0%2C5&q=genify+bank+transformer&btnG="
                        className={linkClassName}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Research
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col xs="6" lg="4">
                <div className="single-footer mb-13 mb-lg-9">
                  <p
                    className="footer-title gr-text-8-f mb-7"
                    style={{ color: "#000" }}
                  >
                    API
                  </p>
                  <ul
                    className="footer-list list-unstyled gr-text-11"
                    style={{ display: "flex" }}
                  >
                    <li className="py-1" style={{ display: "flex" }}>
                      <a
                        href="https://docs.pfm.genify.ai/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ minWidth: "15vw" }}
                        className={linkClassName}
                      >
                        API Documentation
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p className="footer-title gr-text-11 mb-7">Legal</p>
                    <ul className="footer-list list-unstyled gr-text-9">
                      <li className="py-2">
                        <Link to="/" className={linkClassName}>
                          Privacy Policy
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/" className={linkClassName}>
                          Terms &amp; Conditions
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link to="/" className={linkClassName}>
                          Return Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col> */}
            </Row>
          </Col>
        </Row>
        <Row
          style={{ backgroundColor: "#f5f7fa" }}
          className=" pt-10 pb-10 pt-lg-25 px-lg-25 pb-lg-21 px-md-15 px-sm-15 px-xs-15 px-0"
        >
          <Col
            style={{ backgroundColor: "#f5f7fa", width: "100%" }}
            className="px-10 logo-and-icons"
          >
            <Logo white={false} className="footer-logo" />

            <br />
            <ul
              className="social-icons list-unstyled"
              style={{ paddingTop: "0.85rem" }}
            >
              <li className="mr-2">
                <a
                  href="https://www.linkedin.com/company/genify/"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="linked in icon"
                    src={linkedin}
                    style={{ height: "18px", width: "25px" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://medium.com/genifyai"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="medium icon"
                    src={medium}
                    style={{ height: "18px", width: "25px" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://angel.co/company/genify-ai/jobs"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="angellist icon"
                    src={angellist}
                    style={{ width: "18px", color: "#fff" }}
                  />
                </a>
              </li>
              <li className="mr-2">
                <a
                  href="https://github.com/genifyai"
                  target="blank"
                  className={iconClassName}
                >
                  <img
                    className="addedIcons"
                    alt="github icon"
                    src={github}
                    style={{ width: "20px" }}
                  />
                </a>
              </li>
            </ul>
          </Col>
          <Col className="mr-md-25 footer-right-reserved">
            <p>
              Genify AI Technologies is registered in the UAE at ADGM, with
              license 000007634. Genify AI Technologies Ltd. 2023 © All rights
              reserved
            </p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
