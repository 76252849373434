import React, { useContext } from "react";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import { Link as PageLink } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo";
import medium from "../../assets/image/svg/medium.svg";
import linkedin from "../../assets/image/svg/linkedin.svg";
import angellist from "../../assets/image/svg/angellist.svg";

const EzloanFooter = () => {
  const gContext = useContext(GlobalContext);

  const linkClassName =
    gContext.footer.theme === "dark"
      ? "gr-text-color gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  const iconClassName =
    gContext.footer.theme === "dark"
      ? "text-storm gr-hover-text-green"
      : "gr-text-color gr-hover-text-blue";

  //   const [hovered, setHovered] = useState(false);
  const text = "API Documentation";

  // const toggleHover = () => {
  // 	setHovered(!hovered)
  // 	if (hovered == true) setText('API Documentation')
  // 	else {
  // 		setText('Coming soon!')
  // 	}
  // }

  return (
    <>
      <div
        className={`footer-section pt-15 pt-lg-25 pb-lg-21 ${
          gContext.footer.theme === "dark"
            ? "dark-mode-texts bg-blackish-blue"
            : ""
        }`}
        style={{ backgroundColor: "#f5f7fa" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col lg="3" md="4">
              <Logo white={false} className="footer-logo mb-11" />
              {/* <p className="gr-text-11">
                With lots of unique blocks, you can easily build a page without
                coding. Build your next landing page.
              </p> */}
              <Row className="pl-6">
                <Link to="/contact-us" className={linkClassName}>
                  <Button
                    className="gr-hover-y mb-10 p-2"
                    style={{ backgroundColor: "#ff565b", border: "0" }}
                  >
                    Talk to us
                  </Button>
                </Link>
                {/* <br /> */}
                <ul
                  className="social-icons list-unstyled mb-10 mb-lg-0 ml-7 pb-10"
                  style={{ paddingTop: "0.85rem" }}
                >
                  <li className="mr-2">
                    <a
                      href="https://www.linkedin.com/company/genify/"
                      target="blank"
                      className={iconClassName}
                    >
                      <img
                        className="addedIcons"
                        alt="linked in icon"
                        src={linkedin}
                        style={{ height: "18px", width: "25px" }}
                      />
                    </a>
                  </li>
                  <li className="mr-2">
                    <a
                      href="https://medium.com/genifyai"
                      target="blank"
                      className={iconClassName}
                    >
                      <img
                        className="addedIcons"
                        alt="medium icon"
                        src={medium}
                        style={{ height: "18px", width: "25px" }}
                      />
                    </a>
                  </li>
                  <li className="mr-2">
                    <a
                      href="https://angel.co/company/genify-ai/jobs"
                      target="blank"
                      className={iconClassName}
                    >
                      <img
                        className="addedIcons"
                        alt="angellist icon"
                        src={angellist}
                        style={{ width: "18px", color: "#fff" }}
                      />
                    </a>
                  </li>
                </ul>
              </Row>
            </Col>
            <Col lg="8" md="8" className="offset-lg-1">
              <Row>
                <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p
                      className="footer-title gr-text-8 mb-7"
                      style={{ color: "#000" }}
                    >
                      Products
                    </p>
                    <ul className="footer-list list-unstyled gr-text-11">
                      {/* <li className='py-2'>
												<Link to='#transaction' className={linkClassName}>
                                                Borrower Smartphone App
                                                </Link>
											</li> */}
                      <li className="py-2">
                        <Link
                          smooth
                          to="/ezloan#lending-marketplace"
                          className={linkClassName}
                        >
                          Lending Marketplace
                        </Link>
                      </li>
                      <li className="py-2">
                        <Link
                          smooth
                          to="/ezloan#caas"
                          className={linkClassName}
                        >
                          Credit scoring-as-a-Service
                        </Link>
                      </li>
                      <li className="py-2">
                        <PageLink to="/#transaction" className={linkClassName}>
                          Other Products
                        </PageLink>
                      </li>
                      {/* <li className='py-2'>
												<Link to='/carbon-engine' className={linkClassName}>
													Carbon Footprint Engine
												</Link>
											</li> */}
                    </ul>
                  </div>
                </Col>
                {/* <Col xs='6' lg='3'>
									<div className='single-footer mb-13 mb-lg-9'>
										<p className='footer-title gr-text-8 mb-7' style={{ color: '#000' }}>
											Showcase
										</p>
										<ul className='footer-list list-unstyled gr-text-11'>
											<li className='py-2'>
												<Link to='/personal-finance-mgmt' className={linkClassName}>
													Personal Finance Management
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/wealth-mgmt' className={linkClassName}>
													Wealth Management
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/sme-lending' className={linkClassName}>
													SME Lending Lending
												</Link>
											</li>
											<li className='py-2'>
												<Link to='/consumer-lending' className={linkClassName}>
													Consumer Lending
												</Link>
											</li>
										</ul>
									</div>
								</Col> */}
                <Col xs="6" lg="3">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p
                      className="footer-title gr-text-8 mb-7"
                      style={{ color: "#000" }}
                    >
                      About
                    </p>
                    <ul className="footer-list list-unstyled gr-text-11">
                      <li className="py-1">
                        <a
                          href="https://www.linkedin.com/company/genify/"
                          className={linkClassName}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Company
                        </a>
                      </li>

                      <li className="py-1">
                        <a
                          href="https://medium.com/genifyai"
                          className={linkClassName}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Blog
                        </a>
                      </li>
                      <li className="py-1">
                        <a
                          href="https://angel.co/company/genify-ai/jobs"
                          target="_blank"
                          rel="noreferrer"
                          className={linkClassName}
                        >
                          Careers
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col xs="6" lg="3" id="">
                  <div className="single-footer mb-13 mb-lg-9">
                    <p
                      className="footer-title gr-text-8 mb-7"
                      style={{ color: "#000" }}
                    >
                      API
                    </p>
                    <ul className="footer-list list-unstyled gr-text-11">
                      <li className={"py-1"}>
                        <a
                          href="https://docs.ezloan.genify.ai/"
                          target="_blank"
                          className={linkClassName}
                        >
                          {text}
                        </a>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EzloanFooter;
