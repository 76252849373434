import React, { useState, useContext } from "react";
import styled from "styled-components";
import { Container, Navbar } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { AnchorLink as Link } from "gatsby-plugin-anchor-links";
import GlobalContext from "../../context/GlobalContext";
import EzloanLogo from "../../assets/image/ezloan_logo/SVG/Logo_Blue.svg";
import CartContext from "../../context/CartContext";
import Offcanvas from "../Offcanvas";
import NestedMenu from "../NestedMenu";
import { device } from "../../utils";
import Logo from "../Logo";
import { menuItems } from "./menuItems";
import "../../addedcss/styles.scss";

const SiteHeader = styled.header`
  padding: 10px 0 10px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  @media ${device.lg} {
    position: fixed !important;
    transition: 0.6s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.6s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.heading : theme.colors.light};
    }
  }
  @media ${device.xs} {
    position: fixed !important;
    transition: 0.6s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.6s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.heading : theme.colors.light};
    }
  }
  @media ${device.md} {
    position: fixed !important;
    transition: 0.6s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.6s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.heading : theme.colors.light};
    }
  }
  @media ${device.sm} {
    position: fixed !important;
    transition: 0.6s;
    &.scrolling {
      transform: translateY(-100%);
      transition: 0.6s;
    }
    &.reveal-header {
      transform: translateY(0%);
      box-shadow: 0 12px 34px -11px rgba(65, 62, 101, 0.1);
      z-index: 9999;
      background: ${({ dark, theme }) =>
        dark ? theme.colors.heading : theme.colors.light};
    }
    &.test-value {
      text-align: left;
      color: red;
    }
  }
`;

const ToggleButton = styled.button`
  color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.lightShade}!important;
  border-color: ${({ dark, theme }) =>
    dark ? theme.colors.lightShade : theme.colors.heading}!important;
`;

const Header = () => {
  const gContext = useContext(GlobalContext);
  const cContext = useContext(CartContext);
  const [showScrolling, setShowScrolling] = useState(false);
  const [showReveal, setShowReveal] = useState(false);
  var menuItemsBasedOnSource = menuItems;
  useScrollPosition(({ prevPos, currPos }) => {
    if (currPos.y > 400) {
      setShowScrolling(true);
    } else {
      setShowScrolling(false);
    }
    if (currPos.y > -1) {
      setShowReveal(false);
    } else {
      setShowReveal(true);
    }
  });

  if (gContext.header.source === "ezloan") {
    menuItemsBasedOnSource = [
      {
        name: "products",
        label: "Products",
        items: [
          {
            label: "For Business:",
            items: [
              {
                name: "ezloan#lending-marketplace",
                label: "Lending Marketplace",
              },
              { name: "ezloan#caas", label: "Credit scoring-as-a-Service" },
            ],
          },
          // {
          // 	label: 'For Individual:',
          // 	items: [{ name: '#borrowerapp', label: 'Borrower Smartphone App' }]
          // }
        ],
      },
      {
        name: "about",
        label: "About",
        items: [
          {
            name: "https://www.linkedin.com/company/genify/",
            label: "Company",
            isExternal: true,
          },
          {
            name: "https://angel.co/company/genify-ai/jobs",
            label: "Careers",
            isExternal: true,
          },
          {
            name: "https://medium.com/genifyai",
            label: "Blog",
            isExternal: true,
          },
          {
            name: "https://medium.com/genifyai",
            label: "Researh",
            isExternal: true,
          },
        ],
      },

      {
        name: "https://docs.ezloan.genify.ai/",
        label: "API Documentation",
        isExternal: true,
      },
      {
        name: "contact-us",
        label: "Talk to us",
      },
    ];
  }
  return (
    <>
      <SiteHeader
        className={`site-header  site-header--absolute py-0 sticky-header ${
          gContext.header.align === "left"
            ? "site-header--menu-left "
            : gContext.header.align === "right"
            ? "site-header--menu-right "
            : "site-header--menu-center "
        }
        ${
          gContext.header.theme === "dark"
            ? "dark-mode-texts"
            : "dark-mode-texts"
        } ${showScrolling ? "scrolling" : ""} ${
          showReveal && gContext.header.theme === "dark"
            ? "reveal-header"
            : showReveal
            ? "reveal-header"
            : ""
        }`}
        // style={
        //   showReveal
        //     ? gContext.header.theme === "green"
        //       ? { backgroundColor: "#7fb4a1" }
        //       : { backgroundColor: "#fff", background: "rgb(255, 255, 255,1)" }
        //     : null
        // }
        style={
          gContext.header.theme === "dark" && !showReveal
            ? null
            : gContext.header.theme === "green"
            ? { backgroundColor: "#7fb4a1" }
            : { backgroundColor: "#fff", background: "rgb(74, 99, 165)" }
        }
      >
        <Container
          fluid={gContext.header.isFluid}
          className={gContext.header.isFluid ? "pr-lg-9 pl-lg-9" : ""}
        >
          <nav className="navbar site-navbar offcanvas-active navbar-expand-xl  px-0">
            {/* <!-- Brand Logo--> */}
            {gContext.header.source === "genify" ? (
              <div className="brand-logo">
                <Logo white={true} />
              </div>
            ) : (
              <Navbar.Brand href="/" style={{ color: "white" }}>
                <div
                  style={{ display: "flex", marginLeft: "-30px" }}
                  className="brand-logo"
                >
                  <img
                    src={EzloanLogo}
                    style={{ height: "80px" }}
                    alt="ezloan logo"
                  />
                  <div style={{ marginTop: "10px", marginLeft: "-25px" }}>
                    <h1
                      style={{ color: "rgb(65, 97, 166)", fontSize: "2.3rem" }}
                    >
                      EZloan
                      <br />
                      <p
                        style={{
                          color: "rgb(65, 97, 166)",
                          lineHeight: "0",
                          marginTop: "5px",
                          fontWeight: "500",
                        }}
                      >
                        by Genify
                      </p>
                    </h1>
                  </div>
                </div>
              </Navbar.Brand>
            )}
            <div className="collapse navbar-collapse">
              <div className="navbar-nav-wrapper">
                <ul className="navbar-nav main-menu d-none d-lg-flex">
                  {menuItemsBasedOnSource.map(
                    (
                      { label, isExternal = false, name, items, ...rest },
                      index
                    ) => {
                      // console.log(items);
                      const hasSubItems = Array.isArray(items);
                      return (
                        <React.Fragment key={name + index}>
                          {hasSubItems ? (
                            <li className="nav-item dropdown" {...rest}>
                              <a
                                className="nav-link dropdown-toggle gr-toggle-arrow"
                                role="button"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={{ color: "#565d67" }}
                                href="/#"
                              >
                                {label}
                                <i className="icon icon-small-down"></i>
                              </a>
                              <ul className="gr-menu-dropdown dropdown-menu">
                                {items.map((subItem, indexSub) => {
                                  const hasInnerSubItems = Array.isArray(
                                    subItem.items
                                  );
                                  // console.log("test", hasInnerSubItems);
                                  if (hasInnerSubItems === true)
                                    console.log("check", subItem.items);

                                  return (
                                    <React.Fragment
                                      key={subItem.name + indexSub}
                                    >
                                      {hasInnerSubItems ? (
                                        <li className="drop-menu-item dropdown">
                                          <a
                                            className="dropdown-toggle gr-toggle-arrow"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-expanded="false"
                                            aria-haspopup="true"
                                            href="/#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                            }}
                                          >
                                            {subItem.label}
                                            <i className="icon icon-small-down"></i>
                                          </a>
                                          <ul className="gr-menu-dropdown dropdown-menu dropdown">
                                            {subItem.items.map(
                                              (itemInner, indexInnerMost) => (
                                                <li
                                                  className="drop-menu-item"
                                                  key={
                                                    itemInner.name +
                                                    indexInnerMost
                                                  }
                                                >
                                                  {itemInner.isExternal ? (
                                                    <a
                                                      href={`${itemInner.name}`}
                                                      target="_blank"
                                                      rel="noopener noreferrer"
                                                    >
                                                      {itemInner.label}
                                                    </a>
                                                  ) : (
                                                    <Link
                                                      to={`/${itemInner.name}`}
                                                    >
                                                      {itemInner.label}
                                                    </Link>
                                                  )}
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </li>
                                      ) : (
                                        <li className="drop-menu-item">
                                          {subItem.isExternal ? (
                                            <a
                                              href={`${subItem.name}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              onClick={() => {
                                                // console.log(
                                                //   "a tag name",
                                                //   subItem.name,
                                                //   subItem
                                                // );

                                                if (
                                                  subItem.label == "Jobs" ||
                                                  subItem.label == "Company"
                                                ) {
                                                  // console.log(
                                                  //   "reached data layer",
                                                  //   subItem.label,
                                                  //   gContext.header.path
                                                  // );
                                                  window.dataLayer.push({
                                                    page_type:
                                                      gContext.header.path,
                                                    event: "select_company",
                                                    subcategory: subItem.label,
                                                  });
                                                }
                                              }}
                                            >
                                              {subItem.label}
                                            </a>
                                          ) : (
                                            <Link
                                              onAnchorLinkClick={() => {
                                                if (
                                                  [
                                                    "recommendation-api",
                                                    "recommendation-api",
                                                    "pfm",
                                                    "credit-scoring-api",
                                                  ].includes(subItem.name)
                                                ) {
                                                  // console.log(
                                                  //   "reached data layer",
                                                  //   subItem.label
                                                  // );
                                                  window.dataLayer.push({
                                                    page_type:
                                                      gContext.header.path,
                                                    event: "select_product",
                                                    product_type: subItem.label,
                                                  });
                                                }
                                                if (
                                                  subItem.name == "blog" ||
                                                  subItem.label == "Company"
                                                ) {
                                                  // console.log(
                                                  //   "reached data layer",
                                                  //   subItem.label,
                                                  //   gContext.header.path
                                                  // );

                                                  window.dataLayer.push({
                                                    page_type:
                                                      gContext.header.path,
                                                    event: "select_company",
                                                    subcategory: subItem.label,
                                                  });
                                                }
                                              }}
                                              to={`/${subItem.name}`}
                                            >
                                              {subItem.label}
                                            </Link>
                                          )}
                                        </li>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </ul>
                            </li>
                          ) : (
                            <li className="nav-item" {...rest}>
                              {isExternal ? (
                                <a
                                  onClick={() => {
                                    window.dataLayer.push({
                                      page_type: gContext.header.path,
                                      event: "select_apidoc",
                                    });
                                  }}
                                  className="nav-link"
                                  href={
                                    label === "API Documentation" &&
                                    gContext.header.source === "ezloan"
                                      ? "https://docs.ezloan.genify.ai/"
                                      : label === "API Documentation" &&
                                        gContext.header.source === "genify"
                                      ? "//docs.pfm.genify.ai/"
                                      : `${name}`
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {label}
                                </a>
                              ) : (
                                <Link
                                  className={`nav-link  ${label ===
                                    "Talk to us" && "cta"}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  to={`/${name}`}
                                  role="button"
                                  aria-expanded="false"
                                >
                                  <span
                                    onClick={() =>
                                      window.dataLayer.push({
                                        page_type: gContext.header.path,
                                        event: "contact_us",
                                      })
                                    }
                                    className={
                                      label === "Talk to us" &&
                                      "contactus-header-label"
                                    }
                                    style={
                                      label === "Talk to us"
                                        ? {
                                            backgroundColor: "rgb(255, 86, 91)",
                                            padding: "10px",
                                            borderRadius: "10px",
                                          }
                                        : null
                                    }
                                  >
                                    {label}
                                    {label === "Talk to us" && (
                                      <span>
                                        <svg
                                          width="46px"
                                          height="23px"
                                          viewBox="0 0 66 43"
                                          version="1.1"
                                        >
                                          <g
                                            id="arrow"
                                            stroke="none"
                                            stroke-width="1"
                                            fill="none"
                                            fill-rule="evenodd"
                                          >
                                            <path
                                              class="one"
                                              d="M40.1543933,3.89485454 L43.9763149,0.139296592 C44.1708311,-0.0518420739 44.4826329,-0.0518571125 44.6771675,0.139262789 L65.6916134,20.7848311 C66.0855801,21.1718824 66.0911863,21.8050225 65.704135,22.1989893 C65.7000188,22.2031791 65.6958657,22.2073326 65.6916762,22.2114492 L44.677098,42.8607841 C44.4825957,43.0519059 44.1708242,43.0519358 43.9762853,42.8608513 L40.1545186,39.1069479 C39.9575152,38.9134427 39.9546793,38.5968729 40.1481845,38.3998695 C40.1502893,38.3977268 40.1524132,38.395603 40.1545562,38.3934985 L56.9937789,21.8567812 C57.1908028,21.6632968 57.193672,21.3467273 57.0001876,21.1497035 C56.9980647,21.1475418 56.9959223,21.1453995 56.9937605,21.1432767 L40.1545208,4.60825197 C39.9574869,4.41477773 39.9546013,4.09820839 40.1480756,3.90117456 C40.1501626,3.89904911 40.1522686,3.89694235 40.1543933,3.89485454 Z"
                                              fill="#FFFFFF"
                                            ></path>
                                            <path
                                              class="two"
                                              d="M20.1543933,3.89485454 L23.9763149,0.139296592 C24.1708311,-0.0518420739 24.4826329,-0.0518571125 24.6771675,0.139262789 L45.6916134,20.7848311 C46.0855801,21.1718824 46.0911863,21.8050225 45.704135,22.1989893 C45.7000188,22.2031791 45.6958657,22.2073326 45.6916762,22.2114492 L24.677098,42.8607841 C24.4825957,43.0519059 24.1708242,43.0519358 23.9762853,42.8608513 L20.1545186,39.1069479 C19.9575152,38.9134427 19.9546793,38.5968729 20.1481845,38.3998695 C20.1502893,38.3977268 20.1524132,38.395603 20.1545562,38.3934985 L36.9937789,21.8567812 C37.1908028,21.6632968 37.193672,21.3467273 37.0001876,21.1497035 C36.9980647,21.1475418 36.9959223,21.1453995 36.9937605,21.1432767 L20.1545208,4.60825197 C19.9574869,4.41477773 19.9546013,4.09820839 20.1480756,3.90117456 C20.1501626,3.89904911 20.1522686,3.89694235 20.1543933,3.89485454 Z"
                                              fill="#FFFFFF"
                                            ></path>
                                            <path
                                              class="three"
                                              d="M0.154393339,3.89485454 L3.97631488,0.139296592 C4.17083111,-0.0518420739 4.48263286,-0.0518571125 4.67716753,0.139262789 L25.6916134,20.7848311 C26.0855801,21.1718824 26.0911863,21.8050225 25.704135,22.1989893 C25.7000188,22.2031791 25.6958657,22.2073326 25.6916762,22.2114492 L4.67709797,42.8607841 C4.48259567,43.0519059 4.17082418,43.0519358 3.97628526,42.8608513 L0.154518591,39.1069479 C-0.0424848215,38.9134427 -0.0453206733,38.5968729 0.148184538,38.3998695 C0.150289256,38.3977268 0.152413239,38.395603 0.154556228,38.3934985 L16.9937789,21.8567812 C17.1908028,21.6632968 17.193672,21.3467273 17.0001876,21.1497035 C16.9980647,21.1475418 16.9959223,21.1453995 16.9937605,21.1432767 L0.15452076,4.60825197 C-0.0425130651,4.41477773 -0.0453986756,4.09820839 0.148075568,3.90117456 C0.150162624,3.89904911 0.152268631,3.89694235 0.154393339,3.89485454 Z"
                                              fill="#FFFFFF"
                                            ></path>
                                          </g>
                                        </svg>
                                      </span>
                                    )}
                                  </span>
                                </Link>
                              )}
                            </li>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>

            {gContext.header.button === "cta" && (
              <div className="header-btn ml-auto ml-lg-0 mr-6 mr-lg-0 d-none d-xs-block">
                <Link to="/#" className={`btn btn-${gContext.header.variant}`}>
                  {gContext.header.buttonText}
                </Link>
              </div>
            )}

            {gContext.header.button === "cart" && (
              <Link to="/cart" className="header-cart">
                <i className="fas fa-shopping-cart"></i>
                <span className="car">{cContext.products.length}</span>
              </Link>
            )}

            {gContext.header.button === "account" && (
              <div className="header-btns d-none d-xs-block  ml-auto ml-lg-3 mr-6 mr-lg-0">
                <Link
                  className="btn-link gr-text-11 font-weight-bold gr-text-color pl-lg-8 ml-lg-2 border-lg-left mr-9 mr-lg-0"
                  to="/signin"
                >
                  Sign In
                </Link>
                <Link
                  className={`btn btn-${gContext.header.variant} px-9 py-5 ml-lg-9`}
                  to="/signup"
                >
                  Sign Up
                </Link>
              </div>
            )}

            <ToggleButton
              className={`navbar-toggler btn-close-off-canvas ml-3 ${
                gContext.visibleOffCanvas ? "collapsed" : ""
              }`}
              type="button"
              data-toggle="collapse"
              data-target="#mobile-menu"
              aria-controls="mobile-menu"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={gContext.toggleOffCanvas}
              dark={gContext.header.theme === "dark" ? 1 : 0}
              style={{ backgroundColor: "rgb(74, 99, 165)" }}
            >
              <i
                className="icon icon-menu-34 icon-burger d-block"
                style={{ color: "#fff" }}
              ></i>
            </ToggleButton>
          </nav>
        </Container>
      </SiteHeader>
      <Offcanvas
        show={gContext.visibleOffCanvas}
        onHideOffcanvas={gContext.toggleOffCanvas}
      >
        <NestedMenu menuItems={menuItemsBasedOnSource} />
      </Offcanvas>
    </>
  );
};
export default Header;
