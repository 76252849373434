// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-apidoc-js": () => import("./../../../src/pages/apidoc.js" /* webpackChunkName: "component---src-pages-apidoc-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogv-2-js": () => import("./../../../src/pages/blogv2.js" /* webpackChunkName: "component---src-pages-blogv-2-js" */),
  "component---src-pages-blogv-3-js": () => import("./../../../src/pages/blogv3.js" /* webpackChunkName: "component---src-pages-blogv-3-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-credit-scoring-api-js": () => import("./../../../src/pages/credit-scoring-api.js" /* webpackChunkName: "component---src-pages-credit-scoring-api-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-ezloan-js": () => import("./../../../src/pages/ezloan.js" /* webpackChunkName: "component---src-pages-ezloan-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pfm-js": () => import("./../../../src/pages/pfm.js" /* webpackChunkName: "component---src-pages-pfm-js" */),
  "component---src-pages-recommendation-api-js": () => import("./../../../src/pages/recommendation-api.js" /* webpackChunkName: "component---src-pages-recommendation-api-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-transaction-categorization-api-js": () => import("./../../../src/pages/transaction-categorization-api.js" /* webpackChunkName: "component---src-pages-transaction-categorization-api-js" */),
  "component---src-pages-txn-data-gui-js": () => import("./../../../src/pages/txn-data-gui.js" /* webpackChunkName: "component---src-pages-txn-data-gui-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

