import React from "react";
import { Link } from "gatsby";

// import imgL1Logo from "../../assets/image/genify-logo-main.png";
import imgL1Logo from "../../assets/image/genify-logo-main.webp";
// import imgL1LogoWhite from "../../assets/image/genify-logo-white.png";
import imgL1LogoWhite from "../../assets/image/genify-logo-white.webp";

const Logo = ({ white, height, className = "", ...rest }) => {
  return (
    <Link to="/" className={`d-block ${className}`} {...rest}>
      {white ? (
        <img src={imgL1LogoWhite} style={{ height: "50px" }} alt="logo link" />
      ) : (
        <img src={imgL1Logo} style={{ height: "50px" }} alt="logo link" />
      )}
    </Link>
  );
};

export default Logo;
